body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}


a{
  outline:none
}

/* this is to overide the video carousel buttons */
.slick-next:before,
.slick-prev:before {
  font-size: 20px;
  line-height: 1;
  opacity: 0.75;
  color:black;
}



.footer{
  /* background-color: rgba(53, 134, 171, 0.55); */
  height: 15vh;
}


.banner {
  /* background-image: url("https://antipodes-band-bucket.storage.googleapis.com/antipodes3/antipodes3phone.jpg"); */
  background-image: url("https://raw.githubusercontent.com/olinelson/antipodes/master/public/antipodes3/antipodes3phone.jpg");
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;

  height: 66.233766233766234vw;
  /* max-height: 100vh; */

  display: grid !important;
  
}
 @media only screen and (min-width: 480px) {
  .banner {
    background-image: url("https://raw.githubusercontent.com/olinelson/antipodes/master/public/antipodes3/antipodes3tablet.jpg");

  }

}

@media only screen and (min-width: 1024px) {
  .banner {
    background-image: url("https://raw.githubusercontent.com/olinelson/antipodes/master/public/antipodes3/antipodes3big.jpg");

  }
}




.banner-text-container{
  color: white;
  align-self: center;
  justify-self: center;
  width: 90%;
}
.banner-text-container h1 {
  font-size: 10rem;
  /* max-width: 5rem; */

}

 @media only screen and (max-width: 700px) {
 .banner-text-container h1 {
  font-size: 5rem;
  /* max-width: 5rem; */

}

}

.menu .item {
  color: black !important;
  font-size: 1.2rem;

  
}


.menu_bar {
  /* background-color: white !important; */
background-image: linear-gradient(rgba(255, 255, 255, 0.8), rgba(0, 0, 0, 0)) !important;
border: none !important;
}

 .item .active {
  font-size: 10rem !important;
}



/* hamburger menu styles */


/*
Sidebar wrapper styles
Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
*/
.bm-menu-wrap {
  position: fixed;
  height: 100%;
}

/* General sidebar styles */
.bm-menu {
  background: #3585AB;
  padding: 2.5em 1.5em 0;
  font-size: 1.15em;
  overflow:hidden!important;


}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: #373a47;
}

/* Wrapper for item list */
.bm-item-list {
  margin-top: 3rem;
  padding: 0.8em;

}

/* Individual item */
.bm-item {
  display: inline-block;
  color: white;
  font-size: 1.8rem !important;
  margin-top: 1.5rem !important;
}

.bm-item:hover {
  color: rgb(148, 148, 148);
  cursor: pointer;
}





/* Styling of overlay */
.bm-overlay {
  color: #DCDCDC;
}



.quote-box {
  display:grid;
  grid-template-columns: 1fr 8fr 1fr;
}

.quote.right {
  justify-self: flex-end;
}


.image {
  max-width: 80rem!important;
  justify-self: center;
}

.ui.embed {
  max-width: 80rem!important;
}

/* Make side bar colours match */
.menu .item {
  color: white !important;
}


